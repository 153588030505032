import type { FuseResult, IFuseOptions } from 'fuse.js'
import type { MaybeRefOrGetter } from '@vueuse/shared'
import { toValue } from '@vueuse/shared'

// Load Web Workers
import FuseWorker from '~/assets/workers/FuseWorker?worker'

import type { FuseWorkerMessage, FuseWorkerResponse } from '~/assets/workers/FuseWorker'

export type FuseOptions<T> = IFuseOptions<T>
export interface UseFuseOptions<T> {
  fuseOptions?: FuseOptions<T>
  resultLimit?: number
  matchAllWhenSearchEmpty?: boolean
}

export function useFuseSearch<DataItem = any>(
  search: MaybeRefOrGetter<string>,
  data: MaybeRefOrGetter<DataItem[] | null>,
  options?: MaybeRefOrGetter<UseFuseOptions<DataItem>>,
) {
  const fuseWorker = ref<Worker | null>(null)
  const fuseResults = ref<FuseResult<any>[]>([])
  const loading = ref(false)

  function setFuseOptions() {
    const messageData: FuseWorkerMessage<DataItem> = { type: 'options', options: toRaw(toValue(options)?.fuseOptions) ?? {} }
    // console.log('UseFuse: Setting fuse options with', messageData)
    fuseWorker.value?.postMessage(messageData)
  }

  function setFuseData() {
    const messageData: FuseWorkerMessage<DataItem> = { type: 'data', data: toRaw(toValue(data) ?? []) }

    // console.log('UseFuse: Setting fuse data with', messageData)
    fuseWorker.value?.postMessage(messageData)
  }

  function queryFuse() {
    loading.value = true
    const messageData: FuseWorkerMessage<DataItem> = { type: 'search', query: toRaw(toValue(search)), limit: toValue(options)?.resultLimit ?? undefined }
    // console.log('UseFuse: Querying fuse with', messageData)
    fuseWorker.value?.postMessage(messageData)
  }

  watch(
    () => toValue(options)?.fuseOptions,
    () => {
      setFuseOptions()
    },
    { deep: true },
  )

  watch(
    () => toValue(data),
    () => {
      setFuseData()
    },
    { deep: true },
  )
  watch(
    [() => toValue(search), () => toValue(options)?.resultLimit],
    ([_newSearch, _resultLimit]) => {
      queryFuse()
    },
    { deep: true },
  )

  onMounted(() => {
    fuseWorker.value = new FuseWorker()

    fuseWorker.value.onmessage = (e: MessageEvent<FuseWorkerResponse<DataItem>>) => {
      switch (e.data.type) {
        case 'search': {
          if (e.data.query !== toValue(search)) {
            console.log('UseFuse: Ignoring fuse worker response because query has changed')
          }
          else {
            fuseResults.value = e.data.data
            loading.value = false
          }

          break
        }
        default:
          console.error('UseFuse: Unknown worker response type:', e.type)
          break
      }
    }

    setFuseOptions()
    setFuseData()
    queryFuse()
  })

  const results: ComputedRef<FuseResult<DataItem>[]> = computed(() => {
    const resolved = toValue(options)
    if (resolved?.matchAllWhenSearchEmpty && !toValue(search))
      return (toValue(data) ?? []).map((item, index) => ({ item, refIndex: index }))

    return fuseResults.value
  })

  return {
    results,
    loading,
  }
}

export type UseFuseReturn = ReturnType<typeof useFuseSearch>
